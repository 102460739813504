"use client";

import { usePathname } from "next/navigation";
import { useState, useEffect, useMemo } from "react";
import FilterBar, { FilterOptionsType } from "../chart/filter-bar";
import {
  defaultFiltersDisabled,
  pathWiseFilterData,
} from "@/constants/constants";

type PathWiseFilterOptions = {
  [key: string]: FilterOptionsType;
};

export function PageFilter() {
  const pathname = usePathname();
  const [filterOptions, setFilterOptions] = useState<FilterOptionsType>(
    defaultFiltersDisabled
  );

  // Initialize pathWiseFilter using useMemo
  const pathWiseFilter: PathWiseFilterOptions = useMemo(
    () => pathWiseFilterData,
    []
  );
  useEffect(() => {
    setFilterOptions(pathWiseFilter[pathname] || defaultFiltersDisabled);
  }, [pathname, pathWiseFilter]);

  const hasPathFilter = !!pathWiseFilter[pathname];
  if (!hasPathFilter) {
    //only display the filter on pages that use it.
    return null;
  }
  return <FilterBar {...{ filterOptions }} />;
}
